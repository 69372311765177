/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  id: string;
  html: string;
  title: string;
}

const FeaturedImage: FC<Props> = ({ id, html, title }) => {
  const regex = /img src="(\S*)"/g;
  if (html === null || html === undefined) {
    return null;
  }

  const imageURL = html.match(regex);
  if (imageURL === null || imageURL === undefined) {
    return null;
  }
  return (
    <Link to={`https://www.comefx.com/questions/${id}`}>
      <img
        loading="lazy"
        className="card mb-2 col-12"
        style={{ height: '254px' }}
        src={imageURL[0]?.split('"')[1]}
        alt={title ?? ''}
      />
    </Link>
  );
};

export default FeaturedImage;
