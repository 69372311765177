import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'; /* add by lvyang */

import dayjs from 'dayjs';

import { siteInfoStore } from '@/stores';

const Index = () => {
  const fullYear = dayjs().format('YYYY');
  const siteName = siteInfoStore((state) => state.siteInfo.name);
  const cc = `${fullYear} ${siteName}`;
  return (
    <footer className="bg-light">
      <Container className="py-3">
        <p className="text-center mb-0 small text-secondary">
          <Link to="https://www.comefx.com/about"> About Us </Link>|
          <Link to="https://www.comefx.com/contact"> Contact Us </Link>|
          <Link to="https://www.comefx.com/dmca"> DMCA </Link>|
          <Link to="https://www.comefx.com/editorial"> Editorial Policy </Link>|
          <Link to="https://www.comefx.com/privacy"> Privacy Policy </Link>|
          <Link to="https://www.comefx.com/cookies"> Cookies Policy </Link>|
          <span
            className="small link-primary cky-banner-element"
            style={{ cursor: 'pointer' }}>
            {' '}
            Manage Cookies{' '}
          </span>
          |<Link to="https://www.comefx.com/disclaimer"> Disclaimer </Link>|
          <Link to="https://www.comefx.com/terms-and-conditions">
            {' '}
            Terms and Conditions{' '}
          </Link>
          |<a href="https://www.comefx.com/sitemap.xml"> Sitemap </a>
          <br />© {cc}.
        </p>
      </Container>
    </footer>
  );
};

export default React.memo(Index);
